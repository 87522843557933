export const queryKeys = {
  homepage: "homepage",
  applyNowOptions: "applyNowOptions",
  footer: "footer",
  header: "header",
  aboutUs: "aboutUs",
  contactUs: "contactUs",
  vacancies: "vacancies",
  privacyPolicy: "privacyPolicy",
  termsAndConditions: "termsAndConditions",
  qualificationModal: "qualificationModal",
  confirmationModal: "confirmationModal",
  application: "application",
  jobApplication: "jobApplication",
};
